






import { computed, defineComponent } from '@vue/composition-api';
import { IUniqueProps } from './types';
import { componentsWhiteList } from './helpers';

export default defineComponent<IUniqueProps>({
  name: 'Unique',
  inheritAttrs: false,
  components: {
    ATFLearnMore: () => import('~/components/storyblokUnique/ATFLearnMore.vue'),
    StartWithBasePackage: () => import('~/components/storyblokUnique/StartWithBasePackage.vue'),
    BetterEveryDay: () => import('~/components/storyblokUnique/BetterEveryDay.vue'),
    FCAddsToYourRoutine: () => import('~/components/storyblokUnique/FCAddsToYourRoutine.vue'),
    ChooseVibeAnimation: () => import('~/components/storyblokUnique/ChooseVibeAnimation.vue'),
    BlurringTheLinesAnimation: () => import('~/components/storyblokUnique/BlurringTheLinesAnimation.vue'),
    FCCompetitors: () => import('~/components/storyblokUnique/FCCompetitors.vue'),
    ChatWithUs: () => import('~/components/storyblokUnique/ChatWithUs.vue'),
    SetGoalsCarousel: () => import('~/components/storyblokUnique/SetGoalsCarousel.vue'),
    AppCta: () => import('~/components/storyblokUnique/AppCta.vue'),
    Community: () => import('~/components/storyblokUnique/Community.vue'),
    RotatingTextView: () => import('~/components/storyblokUnique/RotatingTextView.vue'),
    ProductsFinancing: () => import('~/components/storyblokUnique/ProductsFinancing.vue'),
    ProductsIncludedThumbnail: () => import('~/components/products/PackageIncludedItems.vue'),
    GQAboveTheFold: () => import('~/components/storyblokUnique/GQAboveTheFold.vue'),
    ConsoleATF: () => import('~/components/storyblokUnique/ConsoleATF.vue'),
    AffirmFinancing: () => import('~/components/storyblokUnique/AffirmFinancing.vue'),
  },
  props: {
    whitelistComponent: {
      type: String,
      default: () => null,
    },
    componentData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const isWhiteListed = computed(() => componentsWhiteList.has(props.whitelistComponent));
    const isComponentData = computed(() => componentsWhiteList.has(props.componentData.componentName));
    const component = computed(() => {
      if (isWhiteListed.value) {
        return props.whitelistComponent;
      }
      if (isComponentData.value) {
        return props.componentData.componentName;
      }

      return null;
    });

    return {
      component,
      expectedAttrs: computed(() => (props.componentData ?? {})),
    };
  },
});
