export const componentsWhiteList = new Set([
  'ATFLearnMore',
  'ScheduleAnimation',
  'StartWithBasePackage',
  'BetterEveryDay',
  'FCAddsToYourRoutine',
  'ChooseVibeAnimation',
  'BlurringTheLinesAnimation',
  'FCCompetitors',
  'ChatWithUs',
  'SetGoalsCarousel',
  'AppCta',
  'Community',
  'RotatingTextView',
  'ProductsFinancing',
  'ProductsIncludedThumbnail',
  'GQAboveTheFold',
  'ConsoleATF',
  'AffirmFinancing',
]);
